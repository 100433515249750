import './Conversation.scss';

import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import { useDropzone } from 'react-dropzone'
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../../../utils/utils";
import browserImageSize from "browser-image-size";

import ConversationType from "../../../../../../types/Conversation";

import {
    clearConversation,
    clearNewMessage,
    clearNewRecipient,
    getConversation,
    getConversations,
    markAsRead,
    resetConversationPagination,
    sendMessage,
    setNewMessage,
    setNewMessageArtifact,
    updateMessages,
    subscribeToMessages,
} from "../../../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";
import { addError } from "../../../../../../store/slices/errors";
import { uploadFiles } from '../../../../../../store/slices/upload';

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import Button, { ButtonThemes, ButtonTypes } from "../../../../../../components/Button";
import ConversationThread from "./components/ConversationThread";
import FileUploadErrorModal from "../../../../../../components/Modal/FileUploadErrorModal";
import Form from "../../../../../../components/Form";
import H5 from "../../../../../../components/H5";
import Icon from "../../../../../../components/Icon";
import ProgressBar from '../../../../../../components/ProgressBar';

const Conversation: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const messageRef = useRef<HTMLSpanElement>();

    const [atEnd, setAtEnd] = useState(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState(false);

    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [preview, setPreview] = useState(null);
    const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);

    const {
        conversation,
        isGettingConversation,
        isSendingMessage,
        newMessage,
        newRecipient,
        websocketStatus,
    } = useTypedSelector((state) => state.directMessages);
    const { uploadPercentage } = useTypedSelector((state) => state.upload);

    const onDrop = async (goodFiles, badFiles) => {

        if (badFiles && badFiles.length > 0) {
            setShowUploadErrorModal(true);
            return
        }
        let file = goodFiles[0];

        setIsUploadingImage(true);
        file.preview = URL.createObjectURL(file);
        setPreview(file);

        // Try getting image size first. This will catch errors before uploading.
        // This process needs to be improved in the next iteration of the admin.
        let imageSize;
        try {
            imageSize = await browserImageSize(file);
        } catch (err) {
            err.response = {
                status: 422 // Error needs a response status to be displayed to user
            }
            err.friendlyMessage = 'Error prepping file for upload. This is likely due to an unsupported file format.';
            setIsUploadingImage(false);
            setPreview(null);
            dispatch(addError(err))
        }

        // If we have image size, proceed with attempting to upload to the server
        if (imageSize) {
            try {
                let { height, width } = imageSize;
                let uploadResult: any = await dispatch(uploadFiles({ file, height, width, type: 'artifact' }));
                dispatch(setNewMessageArtifact(uploadResult.payload.artifactId))
                setIsUploadingImage(false);
            } catch (err) {
                console.warn('upload file err', err);
                dispatch(addError(err))
                setPreview(null);
                setIsUploadingImage(false);
            }
        }
    };

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/jpeg, image/png, image/gif, image/webp, image/heic, image/heif',
        noClick: true,
        noKeyboard: true
    });

    const clearImage = () => {
        setPreview(null);
        dispatch(setNewMessageArtifact(null))
    }

    useEffect(() => {
        const initialize = async () => {

            try {
                let res = await dispatch(getConversation({conversationId: params.conversationId, schoolId: params.schoolId})).unwrap();
                setAtEnd(res.messages.length < 7);
                dispatch(subscribeToMessages({conversationId: params.conversationId}));
                dispatch(markAsRead({conversationId: params.conversationId, schoolId: params.schoolId}));
            } catch (err) {
                console.warn('Conversation initialize err', err);
            }
        }

        if(params?.conversationId) {
            initialize();
        } else {
            console.warn('No conversation ID. Cannot initialize conversation.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.conversationId]);

    useEffect(() => {
        return () => {
            dispatch(clearNewMessage());
            dispatch(clearNewRecipient());
            dispatch(clearConversation());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const recipient = useMemo(
        () => {
            if(!isArrayNullOrEmpty(conversation?.recipients)){
                return conversation.recipients.find((r) => r.isMe !== 'Y');
            } else if(!isObjectNullOrEmpty(newRecipient)) {
                return {isNewConversation: true, profile: newRecipient};
            } else {
                return null;
            }
        },
        [conversation.recipients, newRecipient]
    );

    const handleInfiniteScroll = async () => {
        if(atEnd || isInfiniteScrolling || isGettingConversation) {
            return;
        }

        setIsInfiniteScrolling(true);

        try {
            let res = await dispatch(updateMessages({beforeOrAfter: 'before', hideSpinner: true})).unwrap();
            setAtEnd(res.atEnd);
        } catch(err) {
            console.warn('Conversation handleInfiniteScroll err', err);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    const handleSendMessage = async (e) => {
        try {
            e.preventDefault();
            let res: {conversation?: ConversationType} = await dispatch(sendMessage({schoolId: params?.schoolId})).unwrap();
            dispatch(resetConversationPagination());
            dispatch(getConversations({hideSpinner: true, schoolId: params?.schoolId, profileType: params.profileType}));
            if(!isObjectNullOrEmpty(res.conversation)) {
                setAtEnd(true);
            }
            if(history.location.pathname.includes('new')) {
                history.push(`/school/${params?.schoolId}/${params.profileType}/messages/conversation/${res.conversation.conversationId}`);
            }
            if (messageRef.current) {
                messageRef.current.innerText = '';
            }
            clearImage();
        } catch(err) {
            console.warn('Conversation handleSendMessage err', err);
        }
    }

    return (
        <div {...getRootProps()} className="conversation">
            {(!isObjectNullOrEmpty(conversation) || !isObjectNullOrEmpty(recipient)) && (
                <H5 className="conversation__header">
                    <Button
                        className="conversation__header-back-button"
                        noStyles
                        onClick={() => {
                            history.push(`/school/${params?.schoolId}/${params.profileType}/messages`);

                            setTimeout(() => dispatch(clearConversation()), 500);
                        }}
                    >
                        <Icon type="arrow-left" />
                    </Button>

                    <div className="conversation__header-name">
                        {recipient?.profile?.firstName} {recipient?.profile?.lastName}
                    </div>
                </H5>
            )}

            {isObjectNullOrEmpty(conversation) && !isGettingConversation && (!newRecipient) ? (
                <div className="conversation__empty">
                    No conversation selected. Click on a conversation in the left column to get started.
                </div>
            ) : (
                <>
                    {isGettingConversation ? (
                        <div className="conversation__activity-indicator">
                            <ActivityIndicator />
                        </div>
                    ) : (
                        <>
                            <ConversationThread
                                atEnd={atEnd}
                                handleInfiniteScroll={handleInfiniteScroll}
                                isInfiniteScrolling={isInfiniteScrolling}
                                messages={conversation.messages}
                            />

                            {websocketStatus !== 'connected' && conversation.conversationId && (
                                <div className='c-group-chat__status'>
                                    <Icon type="info" />
                                    <p>Trying to reconnect ({websocketStatus})...</p>
                                </div>
                            )}

                            <Form className="conversation__new-message-form">

                                {preview && (
                                    <div className='conversation__new-message__image-preview'>
                                        <img src={preview.preview} alt="" />
                                        <Button
                                            theme={ButtonThemes.Light}
                                            noStyles
                                            className='conversation__new-message__image-preview__delete'
                                            onClick={() => clearImage()} >
                                            <Icon type='x' />
                                        </Button>
                                    </div>
                                )}
                                <span
                                    className="conversation__new-message-textbox"
                                    contentEditable
                                    onInput={(event: FormEvent<HTMLSpanElement>) => {
                                        const target = event.target as HTMLSpanElement;
                                        if(target) {
                                            dispatch(setNewMessage(target.innerText));
                                        }
                                    }}
                                    placeholder="Send a message..."
                                    ref={messageRef}
                                    role="textbox"
                                />

                                <input {...getInputProps()} />

                                <Button
                                    className="conversation__new-message-button"
                                    noStyles
                                    onClick={open}
                                >
                                    <Icon type="image" />
                                </Button>

                                <Button
                                    className="conversation__new-message-button"
                                    disabled={!newMessage && !preview}
                                    noStyles
                                    onClick={(e) => handleSendMessage(e)}
                                    showActivityIndicator={isSendingMessage}
                                    type={ButtonTypes.Submit}
                                >
                                    <Icon type="send" />
                                </Button>
                            </Form>

                        </>
                    )}
                </>
            )}
            {(isDragActive || isUploadingImage) && (
                <div className='conversation__dragActive'>
                    <Icon type="image" className='conversation__dragActive__icon' />
                    {isUploadingImage ? (
                        <div className="conversation__dragActive__progress">
                            <ProgressBar progress={uploadPercentage} />
                        </div>
                    ) : (
                        <p> Drop an image here to upload. </p>
                    )}

                </div>
            )}

            <FileUploadErrorModal
                close={() => setShowUploadErrorModal(false)}
                show={showUploadErrorModal}
            />
        </div>
    );
};

export default Conversation;

import AdmitsMetadata from "../types/AdmitsMetadata";
import ClassNote from '../types/ClassNote';
import Credentials from "../types/Credentials";
import Deal from "../types/Deal";
import EventsMetadata from "../types/EventsMetadata";
import EventPost from "../types/EventPost";
import FeedMetadata from "../types/FeedMetadata";
import Group from "../types/Group";
import GroupsMetadata from "../types/GroupsMetadata";
import IntegrationEvent from "../types/IntegrationEvent";
import LandingPage from "../types/LandingPage";
import MarketplaceItem from "../types/MarketplaceItem";
import Metadata from "../types/Metadata";
import NewsStory from "../types/NewsStory";
import PastImportMetadata from "../types/PastImportMetadata";
import Poll from "../types/Poll";
import PollOption from "../types/PollOption";
import Profile from "../types/Profile";
import PushNotification from "../types/PushNotification";
import PushNotificationsMetadata from "../types/PushNotificationsMetadata";
import Question from "../types/Question";
import Resource from "../types/Resource";
import Sponsorship from "../types/Sponsorship";
import SponsorshipsMetadata from "../types/SponsorshipsMetadata";
import Tenant from "../types/Tenant";
import Thread from "../types/Thread";
import UnreadGroupChatsMetadata from "../types/UnreadGroupChatsMetadata";
import User from "../types/User";

import {
    GroupCreatorTypes,
    PostStatuses,
    PostTypes,
    PrivacyTypes,
    ProfileStatuses,
    ProfileTypes,
    YesOrNo
} from "./enums";
import { ActivityReportGeneratorState } from '../store/slices/activityReportGenerator';
import AdmitConversionReport from '../types/AdmitConversionReport';
import GivingStatus from '../types/GivingStatus';
import GivingCategory from "../types/GivingCategory";
import GivingOpportunity from "../types/GivingOpportunity";

export const generateAdminConversionReport = (): AdmitConversionReport => {
    return {
        active: 0,
        declined: 0,
        pending: 0,
        promoted: 0,
    };
}

export const generateAdmitDmReportMetadata = (): {totalMessageCount: number} & Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        search: '',
        totalMessageCount: 0,
    };
}


export const generateAlumniMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        alumni_only: true,
        search: '',
    };
}

export const generateUserActivityReportConfig = (): ActivityReportGeneratorState['config'] => {
    return {
        tenants: [],
        includeMyself: false,
        includeGlobalReport: false,
        sendDmReport: false,
        sendActivityReport: false,
    }
}

export const generateClassNote = (): ClassNote => {
    return {
        description: '',
        status: PostStatuses.Active,
        title: '',
        type: PostTypes.ClassNote,
    };
}

export const generateClassNotesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    }
}

export const generateCredentials = (): Credentials => {
    return {
        email: '',
        password: '',
    };
};

export const generateDeal = (): Deal => {
    return {
        description: '',
        redeem: YesOrNo.No,
        status: PostStatuses.Active,
        title: '',
        type: PostTypes.Deal,
    };
};

export const generateDealsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateEvent = (): EventPost => {
    return {
        description: '',
        status: PostStatuses.Active,
        title: '',
        type: PostTypes.Event,
    };
};

export const generateEventsMetadata = (): EventsMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateFeedMetadata = (): FeedMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
        status: PostStatuses.Active,
    };
};

export const generateFlaggedContentMetadata = (): FeedMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'lastFlaggedAt',
        search: ''
    };
};

export const generateGivingStatus = (): GivingStatus => {
    return {
        active: false,
    };
}


export const generateGivingCategory = (): GivingCategory => {
    return {
        name: '',
        description: '',
    };
};

export const generateGivingOpportunity = (): GivingOpportunity => {
    return {
        name: '',
    };
};

export const generateGivingCategoriesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
        total: 0,
    };
};

export const generateGivingOpportunitiesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateGlobalProfilesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'firstName',
        search: '',
    };
}

export const generateGroup = (): Group => {
    return {
        creatorType: GroupCreatorTypes.SchoolAdmin,
        groupChatEnabled: YesOrNo.Yes,
        name: '',
        privacyType: PrivacyTypes.Public,
        title: '',
    };
};

export const generateGroupMembersMetadata = (): Metadata => {
    return {
        page_size: 12,
        page_num: 0,
        order: 'asc',
        sort: 'first_name',
        search: '',
    };
};

export const generateGroupsMetadata = (): GroupsMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        search: '',
    };
};

export const generateIntegrationEvent = (): IntegrationEvent => {
    return {
        description: '',
        status: 'A',
        title: '',
        type: 'E',
    };
};

export const generateLandingPage = (): LandingPage => {
    return {
        backgroundColor: '#ffffff',
        buttonText: 'Welcome',
        overview: '',
        textColor: '#000000',
        welcomeMessage: '',
    };
};

export const generateLandingPagesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'createdAt',
        search: '',
    };
};

export const generateMarketplaceItem = (): MarketplaceItem => {
    return {
        description: '',
        status: PostStatuses.Active,
        title: '',
        type: PostTypes.MarketplaceItem,
    };
};

export const generateMarketplaceItemsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateNewsStoriesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateNewsStory = (): NewsStory => {
    return {
        linkUrl: '',
        title: '',
        type: PostTypes.NewsStory,
    };
};

export const generatePastImportMetadata = (): PastImportMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        search: '',
        type: ProfileTypes.Student,
        status: ProfileStatuses.Active,
    };
};

export const generatePoll = (): Poll => {
    return {
        hideResponses: false,
        options: [
            generatePollOption(0),
            generatePollOption(1),
        ],
        questionText: '',
    };
};

export const generatePollOption = (index?: number): PollOption => {
    return {
        text: '',
        index: index || 0,
    };
};

export const generateProfile = (): Profile => {
    return {
        firstName: '',
        lastName: '',
    };
};

export const generateProfilePromotionsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'createdAt',
        search: '',
    };
}

export const generatePushNotification = (): PushNotification => {
    return {
        message: '',
        title: '',
        type: 'broadcast_push',
        postId: undefined,
        forumTopicId: undefined
    };
};

export const generatePushNotificationsMetadata = (): PushNotificationsMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'date',
        search: '',
    };
};

export const generateQuestion = (): Question => {
    return {
        answer: '',
        question: '',
    };
};

export const generateResource = (): Resource => {
    return {
        linkUrl: '',
        title: '',
    };
};

export const generateSchool = (): Tenant => {
    return {
        name: '',
        postAsProfile: generateProfile(),
    };
};

export const generateSchoolsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        search: ''
    };
};

export const generateSponsorship = (): Sponsorship => ({
    enabled: true,
    global: true,
    linkUrl: '',
    name: '',
});

export const generateSponsorshipsMetadata = (): SponsorshipsMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'date',
        search: '',
    };
};

export const generateStudentCommentsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'created_at',
        search: '',
    };
};

export const generateStudentGroupChatMessagesMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'updated',
        search: '',
    };
};

export const generateStudentDiscussionsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateStudentsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        search: '',
    };
};

export const generateAdmitsMetadata = (): AdmitsMetadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'name',
        search: '',
        assigned_to_me: false,
    };
};

export const generateThread = (): Thread => {
    return {
        status: PostStatuses.Active,
        title: '',
        type: PostTypes.Thread,
    };
};

export const generateThreadsMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: '',
    };
};

export const generateUser = (): User => {
    return {
        emailAddress: '',
        firstName: '',
        lastName: '',
    };
};

export const generateUsersMetadata = (): Metadata => {
    return {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'firstName',
        search: '',
    };
};

export const generateUnreadGroupChatsMetadata = (): UnreadGroupChatsMetadata => {
    return {
        page: 0,
        totalUnreadMessages: 0,
        total: 0
    };
};

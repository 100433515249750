import { stringify as stringifyQueryString } from 'query-string';
import clone from 'clone';

const PATHS = {
    activityReportGenerator: {
      generateActivityReport: () => 'v4/reporting/activity',
      generateDmReport: () => 'v4/reporting/dm',
      generateTenantActivityReport: (tenantId: number) => `v4/tenant/${tenantId}/reporting/activity`,
      generateTenantDmReport: (tenantId: number) => `v4/tenant/${tenantId}/reporting/dm`
    },
    auth: {
        acceptInvitation: () => `v2/user/create/accept_invitation`,
        forgotPassword: () => `v2/user/password/forgot`,
        getElements: (tenantId: number | string) => `v1/element?tenant_id=${tenantId}`,
        getInterests: (tenantId: number | string) => `v1/interest?tenant_id=${tenantId}`,
        getInvitationDetails: (token: string) => `v2/user/create/token?token=${token}`,
        getLoggedInUser: () => `v2/user`,
        login: () => `v2/auth/login`,
        resetPassword: () => `v2/user/password/reset`,
    },
    activityAlerts: {
        getActivityAlerts: (tenantId: number, query: string) => `v2/tenant/${tenantId}/activity?${query}`,
        markActivityAlertsAsViewed: (tenantId: number) => `v2/tenant/${tenantId}/activity/viewed`
    },
    admits: {
        dmDashboard: (tenantId: number, query?: string) => `v4/tenant/${tenantId}/admit/analytics/dm?${query}`,
        conversionDashboard: (tenantId: number) => `v4/tenant/${tenantId}/admit/analytics/conversion`,
        decline: (tenantId: number | string, admitProfileId: number | string) => `v4/tenant/${tenantId}/admit/${admitProfileId}/decline`,
        delete: (tenantId: number | string, admitProfileId: number | string) => `v4/tenant/${tenantId}/admit/${admitProfileId}/delete`,
        getAdmits: (tenantId: number | string, query: string) => `v4/tenant/${tenantId}/admit/search?${query}`,
        inviteViaCsvImport: (tenantId: number | string) => `v4/tenant/${tenantId}/admit/import`,
        promote: (tenantId: number | string, admitProfileId: number | string) => `v4/tenant/${tenantId}/admit/${admitProfileId}/promote`,
        updateWelcomeMessage: (tenantId: number | string, admitProfileId: number | string) => `v4/tenant/${tenantId}/admit/${admitProfileId}/update`,
    },
    alumni: {
        inviteViaCsvImport: (tenantId: number | string) => `v4/tenant/${tenantId}/alumni/import`,
    },
    content: {
        getComments: (tenantId: number, profileId: number, query: string) => `v4/tenant/${tenantId}/profile/${profileId}/comments?${query}`,
        getContent: (tenantId: number, query: string) => `v4/tenant/${tenantId}/post/search?${query}`,
    },
    dashboard: {
        getSuperAdminDashboard: () => 'v2/dashboard',
        getTenantDashboard: (tenantId) => `v2/tenant/${tenantId}/dashboard`
    },
    classNotes: {
        getById: (tenantId, postId) => `v2/tenant/${tenantId}/post/${postId}`,
        create: (tenantId) => `v4/tenant/${tenantId}/note`,
        update: (tenantId, postId) => `v4/tenant/${tenantId}/note/${postId}/update`,
        delete: (tenantId, postId) => `v4/tenant/${tenantId}/note/${postId}/delete`,
    },
    deals: {
        getById: (tenantId, postId) => `v2/tenant/${tenantId}/post/${postId}`,
        create: (tenantId) => `v2/tenant/${tenantId}/deal`,
        update: (tenantId, postId) => `v2/tenant/${tenantId}/deal/${postId}`,
        delete: (tenantId, postId) => `v2/tenant/${tenantId}/deal/${postId}`,
        resetRedemptions: (tenantId, postId) => `v2/tenant/${tenantId}/deal/${postId}/reset_redemptions`,
    },
    directMessages: {
        connectToWebsocket: () => `profile`,
        deleteMessage: (tenantId, conversationId, messageId) => `v4/tenant/${tenantId}/conversation/${conversationId}/${messageId}/delete`,
        getConversations: (tenantId, query) => `v4/tenant/${tenantId}/conversation?${query}`,
        sendMessage: (tenantId) => `v4/tenant/${tenantId}/message`,
        getConversation: (tenantId, conversationId) => `v4/tenant/${tenantId}/conversation/${conversationId}/message`,
        markAsRead: (tenantId, conversationId, conversationMessageId) => `v4/tenant/${tenantId}/conversation/${conversationId}/message/${conversationMessageId}/read`,
        updateConversation: (tenantId, conversationId, beforeOrAfter, messageId) => `v4/tenant/${tenantId}/conversation/${conversationId}/message?${beforeOrAfter}=${messageId}`,
    },
    engineering: {
        getApiRequests: (query) => `v2/engineering/dashboard/api_requests?${query}`,
        getCronJobs: (query) => `v2/engineering/dashboard/cron_jobs/list?${query}`,
        getCronJobStatus: () => `v2/engineering/dashboard/cron_jobs/status`,
        getSqsMessages: (query) => `v2/engineering/dashboard/sqs?${query}`,
        getSystemHealth: (query) => `v4/system_health?${query}`,
        triggerCronJob: () => `v2/engineering/dashboard/cron_jobs/trigger`,
    },
    events: {
        checkIn: (tenantId, postId) => `v4/tenant/${tenantId}/event/${postId}/check_in`,
        removeCheckIn: (tenantId, postId) => `v4/tenant/${tenantId}/event/${postId}/check_in/remove`,
        create: (tenantId) => `v2/tenant/${tenantId}/event`,
        delete: (tenantId, postId) => `v2/tenant/${tenantId}/event/${postId}`,
        getAttendees: (tenantId, postId, query) => `v4/tenant/${tenantId}/event/${postId}/rsvp?${query}`,
        getEvents: (tenantId, query) => `v4/tenant/${tenantId}/post/search?${query}`,
        getById: (tenantId, postId) => `v2/tenant/${tenantId}/post/${postId}`,
        rsvp: (tenantId, postId) => `v4/tenant/${tenantId}/event/${postId}/rsvp`,
        removeRsvp: (tenantId, postId, profileId) => `v4/tenant/${tenantId}/event/${postId}/rsvp/delete?profile_id=${profileId}`,
        update: (tenantId, postId) => `v2/tenant/${tenantId}/event/${postId}`,
    },
    faq: {
        create: (tenantId) => `v4/tenant/${tenantId}/faq`,
        delete: (tenantId, tenantFaqId) => `v4/tenant/${tenantId}/faq/${tenantFaqId}/delete`,
        get: (tenantId, query) => `v4/tenant/${tenantId}/faq/search?${query}`,
        reorder: (tenantId) => `v4/tenant/${tenantId}/faq/reorder`,
        update: (tenantId, tenantFaqId) => `v4/tenant/${tenantId}/faq/${tenantFaqId}/update`,
    },
    giving: {
        deleteCategory: (tenantId, categoryId) => `v4/tenant/${tenantId}/giving/category/${categoryId}/delete`,
        deleteOpportunity: (tenantId, opportunityId) => `v4/tenant/${tenantId}/giving/opportunity/${opportunityId}/delete`,
        getCategories: (tenantId, query) => `v4/tenant/${tenantId}/giving/category/search?${query}`,
        getOpportunities: (tenantId, query) => `v4/tenant/${tenantId}/giving/opportunity/search?${query}`,
        createCategory: (tenantId) => `v4/tenant/${tenantId}/giving/category/`,
        reorderCategory: (tenantId) => `v4/tenant/${tenantId}/giving/category/reorder`,
        feature: (tenantId) => `v4/tenant/${tenantId}/giving/featured/new`,
        unFeature: (tenantId) => `v4/tenant/${tenantId}/giving/featured/remove`,
        updateCategory: (tenantId, categoryId) => `v4/tenant/${tenantId}/giving/category/${categoryId}/update`,
        updateOpportunity: (tenantId, opportunityId) => `v4/tenant/${tenantId}/giving/opportunity/${opportunityId}`,
    },
    globalProfiles: {
        create: () => `v4/global/profile`,
        delete: (profileId: number | string) => `v4/global/profile/${profileId}/delete`,
        getById: (profileId: number | string) => `v4/global/profile/${profileId}`,
        search: () => `v4/global/profile/search?page=0&page_size=1000`,
        setActive: (profileId: number | string) => `v4/global/profile/${profileId}/active`,
        update: (profileId: number | string) => `v4/global/profile/${profileId}/update`,
    },
    groups: {
        approveOrRejectMemberRequest: (tenantId, forumTopicId, forumTopicProfileId, action) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/${forumTopicProfileId}/${action}`,
        create: (tenantId) => `v2/tenant/${tenantId}/forum_topic`,
        delete: (tenantId, postId) => `v2/tenant/${tenantId}/forum_topic/${postId}/delete`,
        get: (tenantId, query) => `v2/tenant/${tenantId}/forum_topic/search?${query}`,
        getById: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}`,
        getMembersActive: (tenantId, forumTopicId, query) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/active?${query}`,
        getMembersRecent: (tenantId, forumTopicId, query) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/active?${query}`,
        getMembersPendingInvited: (tenantId, forumTopicId, query) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/pending/invited?${query}`,
        getMembersPendingRequested: (tenantId, forumTopicId, query) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/pending/requested?${query}`,
        getThreads: (tenantId, query) => `v4/tenant/${tenantId}/post/search?${query}`,
        inviteAllStudentsToGroup: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/invite/bulk/all`,
        inviteEmailListToGroup: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/invite/bulk/email`,
        inviteProfileToGroup: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/invite/`,
        inviteStudentsToGroup: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/invite/bulk`,
        join: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/follow`,
        leave: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/unfollow`,
        removeMember: (tenantId, forumTopicId, forumTopicProfileId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/membership/${forumTopicProfileId}/remove`,
        update: (tenantId, postId) => `v2/tenant/${tenantId}/forum_topic/${postId}/update`,
    },
    groupChat: {
        connectToWebsocket: () => `profile`,
        deleteMessage: (tenantId, forumTopicId, forumTopicMessageId) => `v2/tenant/${tenantId}/forum_topic/chat/${forumTopicId}/message/${forumTopicMessageId}/delete`,
        getMessages: (tenantId, forumTopicId, beforeOrAfter = null, forumTopicMessageId = null) => `v2/tenant/${tenantId}/forum_topic/chat/${forumTopicId}?page_size=10${beforeOrAfter ? `&${beforeOrAfter}=${forumTopicMessageId}` : ''}`,
        like: (tenantId, forumTopicId, forumTopicMessageId) => `v2/tenant/${tenantId}/forum_topic/chat/${forumTopicId}/message/${forumTopicMessageId}/like`,
        unlike: (tenantId, forumTopicId, forumTopicMessageId) => `v2/tenant/${tenantId}/forum_topic/chat/${forumTopicId}/message/${forumTopicMessageId}/like/delete`,
        markAsRead: (tenantId, forumTopicId, forumTopicMessageId) => `v2/tenant/${tenantId}/forum_topic/chat/${forumTopicId}/message/${forumTopicMessageId}/mark_read`,
        sendMessage: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/chat/${forumTopicId}/message`,
        getUnreadForumTopics: (tenantId, page) => `v2/tenant/${tenantId}/forum_topic/chat/unread?page=${page}&page_size=25`
    },
    integrations: {
        deleteEvent: (tenantId, postStagingId) => `v2/tenant/${tenantId}/post_staging/${postStagingId}`,
        getEventById: (tenantId, postStagingId) => `v2/tenant/${tenantId}/post_staging/${postStagingId}`,
        getEvents: (tenantId, query) => `v2/tenant/${tenantId}/post_staging?${query}`,
        publishEvent: (tenantId, postStagingId) => `v2/tenant/${tenantId}/post_staging/${postStagingId}/publish`,
        saveEvent: (tenantId, postStagingId) => `v2/tenant/${tenantId}/post_staging/${postStagingId}`,
    },
    landingPages: {
        create: (tenantId) => `v2/tenant/${tenantId}/landing_page`,
        delete: (tenantId, landingPageId) => `v2/tenant/${tenantId}/landing_page/${landingPageId}/delete`,
        get: (tenantId, query) => `v2/tenant/${tenantId}/landing_page/search?${query}`,
        getById: (tenantId, landingPageId) => `v2/tenant/${tenantId}/landing_page/${landingPageId}`,
        update: (tenantId, landingPageId) => `v2/tenant/${tenantId}/landing_page/${landingPageId}/update`,
    },
    marketplace: {
        getById: (tenantId, postId) => `v2/tenant/${tenantId}/post/${postId}`,
        create: (tenantId) => `v2/tenant/${tenantId}/item`,
        update: (tenantId, postId) => `v2/tenant/${tenantId}/item/${postId}`,
        delete: (tenantId, postId) => `v2/tenant/${tenantId}/item/${postId}`,
    },
    moderation: {
        getCount: (tenantId) => `v4/tenant/${tenantId}/flag/count`,
        getFlaggedContent: (tenantId: number, query: string) => `v4/tenant/${tenantId}/flag?${query}`,
        moderateContent: (tenantId) => `v4/tenant/${tenantId}/flag/review`,
    },
    news: {
        create: (tenantId) => `v4/tenant/${tenantId}/news`,
        delete: (tenantId, postId) => `v4/tenant/${tenantId}/news/${postId}/delete`,
        get: (tenantId, query) => `v4/tenant/${tenantId}/news/search?${query}`,
        getById: (tenantId, postId) => `v4/tenant/${tenantId}/news/${postId}`,
        update: (tenantId, postId) => `v4/tenant/${tenantId}/news/${postId}/update`,
    },
    presentationData: {
        getPresentationTimeSeriesData: (query) => `v2/engineering/dashboard/presentation/activity/time_series?${query}`,
        getPresentationDetailData: (query) => `v2/engineering/dashboard/presentation/activity/detail?${query}`
    },
    pastImports: {
        getPastImports:(tenantId, query) => `v4/tenant/${tenantId}/profile/import?${query}`,
        resendinvites:(tenantId, profileImportId) => `v4/tenant/${tenantId}/profile/import/${profileImportId}/re_invite`,
    },
    pushNotifications: {
        cancel: (tenantId, notificationId) => `v2/tenant/${tenantId}/notification/${notificationId}/pending`,
        get: (tenantId, query) => `v2/tenant/${tenantId}/notification?${query}`,
        getById: (tenantId, notificationId) => `v2/tenant/${tenantId}/notification/${notificationId}`,
        sendBroadcastPushToTenant: (tenantId) => `v2/tenant/${tenantId}/notification/broadcast`,
        sendTransactionalPushToGroup: (tenantId, forumTopicId) => `v2/tenant/${tenantId}/forum_topic/${forumTopicId}/notification/transactional`,
        update: (tenantId, notificationId) => `v2/tenant/${tenantId}/notification/${notificationId}`,
    },
    resources: {
        create: (tenantId) => `v4/tenant/${tenantId}/resource`,
        delete: (tenantId, tenantResourceId) => `v4/tenant/${tenantId}/resource/${tenantResourceId}/delete`,
        get: (tenantId, query) => `v4/tenant/${tenantId}/resource/search?${query}`,
        reorder: (tenantId) => `v4/tenant/${tenantId}/resource/reorder`,
        update: (tenantId, tenantResourceId) => `v4/tenant/${tenantId}/resource/${tenantResourceId}/update`,
    },
    schools: {
        get: (query: string) => `v2/tenant?${query}`,
        getSingle: (tenantId: number) => `v2/tenant/${tenantId}`,
        getOtherAdminUsers: (tenantId: number, role?: string) => `v2/tenant/${tenantId}/user/other?page=0&page_size=1000${role ? '&role=' + role : ''}`,
        sendStripeAccountUpdateEmail: (tenantId: number) => `v4/tenant/${tenantId}/giving/status/account_link`,
        resendStripeAccountSetupEmail: (tenantId: number) => `v4/tenant/${tenantId}/giving/refresh_link`,
        getGivingStatus: (tenantId: number) => `v4/tenant/${tenantId}/giving/status/`,
        update: (tenantId: number) => `v4/tenant/${tenantId}/update`,
        updateGivingStatus: (tenantId: number) => `v4/tenant/${tenantId}/giving/status/update`,
    },
    sponsorships: {
        delete: (walletSponsorshipId) => `v2/wallet/sponsorship/${walletSponsorshipId}/delete`,
        get: (query: string) => `v2/wallet/sponsorship/search?${query}`,
        save: () => `v2/wallet/sponsorship`,
        update: (walletSponsorshipId) => `v2/wallet/sponsorship/${walletSponsorshipId}/update`,
    },
    students: {
        assignTags: (tenantId: number | string, profileId: number | string) => `v2/tenant/${tenantId}/profile/${profileId}/tags`,
        delete: (tenantId: number | string, profileId: number) => `v2/tenant/${tenantId}/profile/${profileId}/delete`,
        getAllTags: (tenantId: number | string, query: string) => `v2/tenant/${tenantId}/tag?${query}`,
        getGroupChatMessages: (schoolId: number | string, profileId: number | string, query: string) => `v4/tenant/${schoolId}/profile/${profileId}/forum_topic_message?${query}`,
        getImportJobs: (tenantId: number | string, query: string) => `v2/tenant/${tenantId}/profile_import?${query}`,
        getOnlineStudents: (tenantId: number | string, query: string) => `v2/tenant/${tenantId}/profile/active?${query}`,
        getPostsAndComments: (schoolId: number | string, profileId: number | string, query: string) => `v4/tenant/${schoolId}/profile/${profileId}/forum_thread?${query}`,
        getRecentActivity: (schoolId: number | string, query: string) => `v2/tenant/${schoolId}/activity/recent?${query}`,
        getStudentById: (tenantId: number | string, profileId: number) => `v2/tenant/${tenantId}/profile/${profileId}`,
        getStudents: (tenantId: number | string, query: string) => `v2/tenant/${tenantId}/profile?${query}`,
        inviteViaCsvImport: (tenantId: number | string) => `v4/tenant/${tenantId}/profile/import/csv`,
        inviteViaEmail: (tenantId: number | string) => `v2/tenant/${tenantId}/profile/invite`,
        setAlternateEmail: (tenantId: number | string, profileId: number | string) => `v4/tenant/${tenantId}/profile/${profileId}/alternate_email`,
    },
    studentPromotion: {
        getStudentsPendingPromotion: (tenantId: number | string, query: string) => `v4/tenant/${tenantId}/profile/promotion/pending?${query}`,
        initiatePromotion: (tenantId: number | string) => `v4/tenant/${tenantId}/profile/promotion/initiate`,
        approvePendingPromotions: (tenantId: number | string) => `v4/tenant/${tenantId}/profile/promotion/approve`,
    },
    threads: {
        getById: (tenantId, postId) => `v2/tenant/${tenantId}/post/${postId}`,
        create: (tenantId) => `v2/tenant/${tenantId}/forum_thread`,
        update: (tenantId, postId) => `v2/tenant/${tenantId}/forum_thread/${postId}`,
        delete: (tenantId, postId) => `v2/tenant/${tenantId}/forum_thread/${postId}`,
        createComment: (tenantId, postId) => `v4/tenant/${tenantId}/post/${postId}/comment`,
        updateComment: (tenantId, postId) => `v2/tenant/${tenantId}/post/${postId}/comments`,
        deleteComment: (tenantId, postId, postCommentId) => `v2/tenant/${tenantId}/post/${postId}/comments/${postCommentId}`,
    },
    upload: {
        getPresignedUrlForArtifactStaging: (tenantId, filename, height, width) => `v2/tenant/${tenantId}/artifact_staging?file_name=${encodeURIComponent(filename)}&height=${height}&width=${width}`,
        getPresignedUrlForArtifact: (tenantId, filename, height, width) => `v2/tenant/${tenantId}/artifact?file_name=${encodeURIComponent(filename)}&height=${height}&width=${width}`,
        getPresignedUrlForInvitationArtifact: (filename, height, width) => `v2/user/create/artifact?file_name=${encodeURIComponent(filename)}&height=${height}&width=${width}`,
        getPresignedUrlForSponsorship: (filename, height, width) => `v2/artifact?file_name=${encodeURIComponent(filename)}&height=${height}&width=${width}`,
    },
    users: {
        create: () => `v2/user/create/email`,
        delete: () => `v2/user/delete`,
        get: (tenantId: number | string, query: string) => `v2/tenant/${tenantId}/user/search?${query}`,
        resendInvitation: () => `v2/user/create/resend_invitation`,
        update: (userId: number | string) => `v2/user/${userId}/update`,
    },
    wallet: {
        getCategories: (query) => `v2/wallet/category/search?${query}`,
        getCategoriesForTenant: (tenantId, query) => `v2/tenant/${tenantId}/wallet/category/search?${query}`
    }
};

export default PATHS;

export const buildQueryString = (metadata: object) => {
    let clonedMetadata = clone(metadata);

    Object.keys(clonedMetadata).forEach((key) => {
        if(/total/.test(key) || clonedMetadata[key] == null || clonedMetadata[key] === '') {
            delete clonedMetadata[key];
        }
    });
    return stringifyQueryString(clonedMetadata);
};

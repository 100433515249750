
import React, {useEffect, useMemo, useState} from 'react';
import clone from 'clone';

import GivingOpportunity from "../../../../../types/GivingOpportunity";
import TableColumn from "../../../../../types/TableColumn";

import {useAppDispatch} from "../../../../../store";
import {getGivingOpportunities} from "../../../../../store/slices/giving";
import {useTypedSelector} from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import GivingOpportunitiesTableMoreMenu from "../GivingOpportunitiesTableMoreMenu";
import GridList from "../../../../../components/GridList";
import Icon from "../../../../../components/Icon";
import {ListTypes} from "../../../../../utils/enums";
import Modal from "../../../../../components/Modal";
import Table from "../../../../../components/Table";
import TableRowImage from "../../../../../components/Table/TableRowImage";


type Props = {
    handleEditClick: Function,
    selectedListType: ListTypes
}
const GivingOpportunities: React.FC<Props> = ({handleEditClick, selectedListType}) => {
    const dispatch = useAppDispatch();

    const [initialized, setInitialized] = useState(false);
    const { isGettingGivingOpportunities, givingOpportunities, givingOpportunity, givingOpportunitiesMetadata} = useTypedSelector((state) => state.giving);
    const [showFeatureValidationModal, setShowFeatureValidationModal] = useState<boolean>(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getGivingOpportunities({})).unwrap();
            } catch(err) {
                console.log('SchoolGiving initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        if (!initialized) {
            initialize();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showEditModal = () => {
        handleEditClick();
    }

    const data = useMemo(
        () => {
            let clonedOpportunities = [...givingOpportunities];

            clonedOpportunities = clonedOpportunities.map((opportunity) => {
                return {
                    ...opportunity,
                    type: "GivingOpportunity"
                }
            });

            return clonedOpportunities;
        },
        [givingOpportunities]
    );

    const renderMoreMenu = (item: GivingOpportunity) => {
        return (
            <GivingOpportunitiesTableMoreMenu
                item={item}
                setShowNewContentModal={showEditModal}
            />
        );
    };

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={original.artifact || undefined}
                        />
                    ),
                    hasImage: true,
                }, {
                    Header: 'Name',
                    accessor: 'name',
                    sortBy: 'name'
                }, {
                    Header: 'Category',
                    accessor: 'givingCategory.name',
                    sortBy: 'categoryName'
                }, {
                    Header: 'Featured',
                    Cell: ({row: { original }}) => (
                        <>
                            {original.featured && (
                                <Icon type="star" />
                            )}
                        </>
                    ),
                    sortBy: 'featured'
                }
            ];

            columns.push({
                Header: '',
                id: 'actions',
                Cell: ({row: { original }}) => renderMoreMenu(original)
            });
            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(givingOpportunitiesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes,
            };
            return await dispatch(getGivingOpportunities({ isUpdate, givingOpportunitiesMetadata: clonedMetadata})).unwrap();
        } catch (err) {
            console.log('getGivingOpportunities onMetadataChange err', err);
        }
    };
    return (
        <div>
            {isGettingGivingOpportunities ? (
                <ActivityIndicator />
            ) : (
                <>
                    {selectedListType === ListTypes.Table ? (
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={givingOpportunitiesMetadata.page_num}
                            pageSize={givingOpportunitiesMetadata.page_size}
                            sortBy={givingOpportunitiesMetadata.sort}
                            sortOrder={givingOpportunitiesMetadata.order}
                            showPagination={true}
                            totalNumberOfItems={givingOpportunitiesMetadata.total}
                        />
                    ) : (
                        <GridList
                            createCardButtonText="Create a Giving Fund"
                            createCardText="Create an fund"
                            isLastPage={Math.ceil(givingOpportunitiesMetadata.total / givingOpportunitiesMetadata.page_size) === givingOpportunitiesMetadata.page_num + 1}
                            items={data}
                            moreMenuComponent={renderMoreMenu}
                            onNextPageClick={() => onMetadataChange({page_num: givingOpportunitiesMetadata.page_num + 1})}
                            onPreviousPageClick={() => onMetadataChange({page_num: givingOpportunitiesMetadata.page_num - 1})}
                            pageNumber={givingOpportunitiesMetadata.page_num}
                        />
                    )}
                </>
            )}

            <Modal
                title="Before you can feature this fund..."
                show={showFeatureValidationModal}
                confirmButtonOnClick={() => {
                    handleEditClick(givingOpportunity);
                    setShowFeatureValidationModal(false);
                }}
                confirmButtonText="Edit"
                declineButtonOnClick={() => setShowFeatureValidationModal(false)}
                declineButtonText="Cancel"
            >
                A fund must have a description and an image to be featured.
            </Modal>
        </div>
    );
};

export default GivingOpportunities;

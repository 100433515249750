import './DirectMessages.scss';

import React, {useEffect, useMemo, useState} from 'react';
import { useRouteMatch, useHistory } from "react-router";
import clone from 'clone';

import {
    clearNewRecipient,
    getConversations,
    resetConversationPagination,
    setNewRecipient
} from "../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import Profile from "../../../../types/Profile";

import Button, { ButtonSizes } from "../../../../components/Button";
import Card from "../../../../components/Card";
import Conversation from "./components/Conversation";
import ConversationsList from "./components/ConversationsList";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableFilterMenu from "../../../../components/Table/TableFilterMenu";
import TableFilterMenuOption from "../../../../types/TableFilterMenuOption";
import {ProfileStatuses, ProfileTypes} from "../../../../utils/enums";
import { setSearchTerm } from "../../../../store/slices/directMessages";
import TableSearch from "../../../../components/TableSearch";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";

import ListStudents from "../SchoolStudents/ListStudents";
import ListAdmits from "../Admits/ListAdmits";
import ListAlumni from "../Alumni/ListAlumni";

const DirectMessages: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [showConversation, setShowConversation] = useState(false);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const {
        conversation,
        conversationsMetadata,
        searchTerm
    } = useTypedSelector((state) => state.directMessages);

    useEffect(() => {
        document.body.classList.add('direct-messages-page');

        return () => {
            document.body.classList.remove('direct-messages-page');
            dispatch(clearNewRecipient());
            dispatch(resetConversationPagination());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(history.location.state?.newRecipient) {
            dispatch(setNewRecipient(history.location.state.newRecipient));
            history.replace();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state]);

    useEffect(() => {
        setShowConversation(params?.conversationId != null && conversation?.conversationId != null);
    }, [params?.conversationId, conversation?.conversationId]);

    const accountFilterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: !conversationsMetadata.status,
                    onSelect: () => {
                        onMetadataChange({
                            status: undefined
                        }, false);
                    },
                    text: "All"
                },{
                    isActive: conversationsMetadata.status === ProfileStatuses.Active,
                    onSelect: () => {
                        onMetadataChange({
                            status: ProfileStatuses.Active
                        }, false);
                    },
                    text: "Active"
                }, {
                    isActive: conversationsMetadata.status === ProfileStatuses.Pending,
                    onSelect: () => {
                        onMetadataChange({
                            status: ProfileStatuses.Pending
                        }, false);
                    },
                    text: "Pending"
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [conversationsMetadata]
    );

    const handleRowClick = (item: Profile) => {
        if (item.conversationId) {
            history.push(`/school/${params.schoolId}/${params.profileType}/messages/conversation/${item.conversationId}`);
        } else {
            history.push(`/school/${params.schoolId}/${params.profileType}/messages/conversation/new`, { newRecipient: item });
        }
        setShowNewContentModal(false);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(conversationsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };
            if (!changes.status) {
                delete clonedMetadata.status;
            } else {
                clonedMetadata.status = changes.status;
                clonedMetadata.page_num = 0;
                clonedMetadata.atEnd = false;
            }

            if (changes.hasOwnProperty('status')) {
                clonedMetadata.page_num = 0;
                clonedMetadata.atEnd = false;

            }

            return await dispatch(getConversations({
                hideSpinner: false,
                schoolId: params?.schoolId,
                profileType: params.profileType,
                conversationsMetadata: clonedMetadata,
            }));
        } catch(err) {
            console.warn('ListStudents onMetadataChange err', err);
        }
    };

    return (
        <div className="direct-messages">

            <PageHeader>
                <H5>
                    <Icon type="navigation-dms" />

                    Direct Messages
                </H5>
            </PageHeader>

            <TableFilterRow>
                {params.profileType === 'admit' && (
                    <>
                        <TableFilterMenu
                            label="Account Status"
                            options={accountFilterOptions}
                        />
                        <TableFilterRowDivider />
                    </>
                )}

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={() => dispatch(setSearchTerm(''))}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Conversations"
                    searchTerm={searchTerm}
                    size="small"
                />
                <Button
                    onClick={() => setShowNewContentModal(true)}
                    size={ButtonSizes.Small}
                    >
                    New Conversation
                </Button>
            </TableFilterRow>

            <Card className={`direct-messages__container  ${showConversation ? 'direct-messages__container--conversation-open' : ''}`}>
                <div className={`direct-messages__container-inner`}>
                    <ConversationsList />

                    <Conversation />
                </div>
            </Card>

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                {params.profileType === ProfileTypes.Admit && (
                    <ListAdmits
                        onRowClick={handleRowClick}
                        fromDirectMessages={true}
                    />
                )}
                {params.profileType === ProfileTypes.Student && (
                    <ListStudents
                        onRowClick={handleRowClick}
                    />
                )}
                {params.profileType === ProfileTypes.Alumni && (
                    <ListAlumni
                        onRowClick={handleRowClick}
                        fromDirectMessages={true}
                    />
                )}
            </NewContentModal>

        </div>
    );
};

export default DirectMessages;

import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import clone from 'clone';
import { useRouteMatch } from "react-router";

import { getStudentComments } from "../../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import FeedFlaggedBanner from "../../../../../../components/Feed/FeedFlaggedBanner";
import FeedPostAndComment from "../../../../../../components/Feed/FeedPostAndComment";
import TableFlaggedOnlyToggle from "../../../../../../components/Table/TableFlaggedOnlyToggle";

const ViewStudentComments: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [atEnd, setAtEnd] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);

    const { isGettingStudentComments, student, studentComments,  studentCommentsMetadata } = useTypedSelector((state) => state.students);

    useEffect(() => {
        if((isGettingStudentComments || studentComments.length > 0) && !initialized) {
            setInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingStudentComments]);

    const handleFlaggedFilterChange = async (filterByFlaggedOnly) => {
        try {
            let clonedMetadata = clone(studentCommentsMetadata);
            clonedMetadata.flagged = filterByFlaggedOnly;
            clonedMetadata.page_num = 0;
            let res = await dispatch(getStudentComments({profileId: student.profileId, schoolId: params.tenantId, studentCommentsMetadata: clonedMetadata})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentComments handleFlaggedFilterChange err', err);
        }
    };

    const handleInfiniteScroll = async () => {
        if(isGettingStudentComments || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res: any = await dispatch(getStudentComments({isUpdate: true, profileId: student.profileId, schoolId: params.tenantId})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentComments handleInfiniteScroll err', err);
            setAtEnd(true);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    return (
        <div className="view-student__posts view-student__content">
            <TableFlaggedOnlyToggle
                onToggleChange={handleFlaggedFilterChange}
                value={studentCommentsMetadata.flagged}
            />

            {isGettingStudentComments || !initialized ? (
                <div className="view-student__activity-indicator">
                    <ActivityIndicator/>
                </div>
            ) : (
                <>
                    {studentComments.length === 0 ? (
                        <div className="school-feed__empty">
                            There's nothing here.
                        </div>
                    ) : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleInfiniteScroll}
                            hasMore={!atEnd}
                        >
                            {studentComments.map((comment: Comment) => (
                                <React.Fragment
                                    key={comment.postCommentId}
                                >
                                    <FeedFlaggedBanner item={comment.post} />
                                    <FeedPostAndComment comment={comment} post={comment.post}/>
                                </React.Fragment>
                            ))}

                            {isInfiniteScrolling && (
                                <div className="school-feed__infinite-scrolling-indicator">
                                    <ActivityIndicator color="white"/>
                                </div>
                            )}

                            {atEnd && !isInfiniteScrolling && (
                                <div className="school-feed__at-end">
                                    End of feed
                                </div>
                            )}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewStudentComments;
